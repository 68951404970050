import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { routes as appRoutes } from "./Routes"
import { ScrollToSection } from './components';

const App: React.FC = () => {
  return (
     <BrowserRouter>
        <ScrollToSection />
        <Routes>
          {appRoutes.map((route) => route.enabled && (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </BrowserRouter>
  );
};

export default App;
