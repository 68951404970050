
const CallToAction:  React.FC = ({...props }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
        <h2 className="text-3xl tracking-tight text-accent sm:text-4xl">
        Partner with us to enhance your productivity.
        </h2>
        <h2 className="text-3xl tracking-tight text-accent sm:text-4xl">
          Discover the power of our recruitment solutions.
        </h2>
        <div className="mt-10 flex items-center gap-x-6">
          <a
            href="/Contact"
            className="rounded-md bg-accent px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-midtone focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Get started
          </a>
          {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Learn more <span aria-hidden="true">→</span>
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default CallToAction;