export class ContactForm {
    public id: string
    public firstName: string
    public lastName: string
    public email: string
    
    public subject: string
    public message: string
    public contactNumber: string
    public companyName: string
    public recatpchaToken: string
    public messageSent: Date
    public smtpResponse: string

    constructor(data?: any) {
        this.id = data?.id
        this.firstName = data?.firstName
        this.lastName = data?.lastName
        this.email = data?.email
        this.subject = data?.subject
        this.message = data?.message        
        this.contactNumber = data?.contactNumber
        this.companyName = data?.companyName
        this.recatpchaToken = data?.recatpchaToken
        this.messageSent = data?.messageSent
        this.smtpResponse = data?.smtpResponse
    }
}