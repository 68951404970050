export class AppConfig {

  public static get recaptchaSiteKey(): string {
    return process.env.REACT_APP_RECAPTCHA_KEY ?? ''
  }

  public static get apiBaseUrl(): string {
    return process.env.REACT_APP_API_BASE_URL ?? ''
  }

}