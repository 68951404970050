import aboutUsImage from '../images/business_group.jpg'

const About:  React.FC = ({...props }) => {
  return (
    <div id="who-we-are" className="bg-white text-shadow  py-6 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4 w-full h-full relative">
            <img src={aboutUsImage} className="h-auto v-full"   alt="Person Working" />
          </div>
          <div>
            <div className="text-base leading-7 text-shadow lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-shadow sm:text-4xl">
                On a mission to empower remote teams
              </h1>
              <div className="max-w-xl">
                <p className="mt-6">
                  At Direct Global Recruiting, we are dedicated to providing exceptional global recruiting services to clients around the world. Our team is made up of experienced professionals who are committed to matching the right candidates with the right positions. We take a personalized approach to each search, ensuring that we fully understand the needs and requirements of both our clients and the candidates we work with.
                </p>
                <p className="mt-8">
                As a Canadian-based firm, we are well-positioned to assist clients in the North American market, but our services extend far beyond this region. We have experience working with companies and candidates from all corners of the globe, and we are dedicated to building strong relationships that stand the test of time. With our knowledge of international recruiting practices and our commitment to providing exceptional service, we are confident that we can help our clients meet their hiring needs no matter where in the world they may be.
                </p>
              </div>
            </div>
            <div className="mt-10 flex">
              <a href="/contact" className="text-base text-accent font-semibold leading-7">
                Want to learn more? Contact us and someone will be in touch. <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;