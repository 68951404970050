import { Contact, Home } from "./pages"
import {FC} from "react"
import { NotFound } from "./components"

interface Route {
  key: string,
  title: string,
  path: string,
  enabled: boolean,
  component: FC<{}>
}

export const routes: Array<Route> = [
  {
    key: 'home-route',
    title: 'Home',
    path: '/',
    enabled: true,
    component: Home
  },
  {
    key: 'contact-route',
    title: 'Contact',
    path: '/contact',
    enabled: true,
    component: Contact
  },
  {
    key: 'FourOhFour-route',
    title: '404',
    path: '*',
    enabled: true,
    component:NotFound
  }

]