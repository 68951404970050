import clsx from 'clsx'
interface NavLinkProps {
  href: string,
  children: React.ReactNode,
  className?: string
}

const NavLink:  React.FC<NavLinkProps> = ({ href, children, className }) => {
  return (
    <a
      href={href}
      className={clsx('inline-block rounded-lg py-1 px-2 text-sm', className)}  
    >
      {children}
    </a>
  )
}

export default NavLink ;