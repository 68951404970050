interface NotFoundProps {

}

const NotFound:  React.FC<NotFoundProps> = () => {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>The page you requested could not be found.</p>
    </div>
  )
}

export default NotFound ;