import clsx from 'clsx'

interface ContainerProps {
  className?: string,
  children: React.ReactNode;
}

const Container:  React.FC<ContainerProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={clsx('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default Container;
