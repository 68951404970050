import { BrandHeader, Services, Footer, CallToAction, Faqs, About } from "../components"


const Home:  React.FC = () => {
  
  return (
    <>
      <main className="font-montserrat scroll-smooth">
        <BrandHeader  />  
        <Services/>       
        <CallToAction />
        <About />
        <Faqs />
      </main>
      <Footer />
    </>
  )
}

export default Home;