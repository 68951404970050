import { Nav } from "."

import servicesImage from '../images/handshake.jpg'

interface AuthLayoutProps {
  children: React.ReactNode
}

const AuthLayout:  React.FC<AuthLayoutProps> = ({children}) => {
  return (
    <>
      <div className="relative flex min-h-full justify-center md:px-12 lg:px-0">        
        <div className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <Nav includeContact={false}/>
          <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {children}
          </div>
        </div>
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1 ">
          <div className="absolute inset-0 h-full w-full object-cover 
            md:bg-gradient-to-br  md:from-midtone  md:to-highlight
            lg:bg-gradient-to-r lg:from-midtone  lg:to-highlight"
          >
            <div className="hidden lg:block absolute top-[50%] translate-y-[-50%] xl:top-0 xl:left-0 xl:h-[min(100%,1206px)] xl:translate-y-0">
              <img
                className="h-full"
                src={servicesImage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthLayout;
