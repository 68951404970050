import { useEffect } from "react";
import { useLocation } from "react-router-dom";


interface ScrollToSectionProps {
}

const ScrollToSection: React.FC<ScrollToSectionProps> = () => {

  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return null
};

export default ScrollToSection; 