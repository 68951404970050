export class ServerResponse {
  public success: boolean
  public message: string
  public data: string

  constructor(data?: any) {
    this.success = data?.success
    this.message = data?.message
    this.data = data?.data
  }
}