import axios from "axios"
import { AppConfig } from "../AppConfig"
import { ContactForm, ServerResponse } from "../models"

export class ContactFormService {

  public async submit(contactForm: ContactForm): Promise<ServerResponse> {
    try {
      const response = await axios({
        method: 'post',
        url: `api/contact`,
        data: {
          contactForm
        }
      })
      return new ServerResponse({
        success: response.data.success,
        message: response.data.message,
        data: response.data
      })
    } catch (error) {
      console.log(error)
      return new ServerResponse({
        success:false,
        message: "Error sending email"
      })
    }

    
  }
}