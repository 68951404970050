import { Container, Logo, NavLink } from "./"

const Footer:  React.FC = () => {
  return (
    <footer className="bg-midtone text-white">
      <Container>
        <div className="py-16">
          <a href="#" aria-label="Home" className="flex justify-center h-[115px] w-auto">
            <Logo />
          </a>
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <NavLink href="#services" className="text-white hover:bg-highlight hover:text-shadow">Services</NavLink>
              <NavLink href="#who-we-are" className="text-white hover:bg-highlight hover:text-shadow">Who We Are</NavLink>
              <NavLink href="#faq" className="text-white hover:bg-highlight hover:text-shadow">FAQ</NavLink>
              <NavLink href="/Contact" className="text-white hover:bg-highlight hover:text-shadow">Contact</NavLink>
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center">
          <p className="mt-6 text-sm text-white sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} Direct Global Recruiting. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer;
