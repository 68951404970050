import { FaArrowRight, FaHandshake, FaMoneyCheckAlt, FaPeopleArrows } from 'react-icons/fa'

const services = [
  {
    name: 'Sourcing & Recruiting',
    bullets: [
      'Sourcing talent without borders',
      'Source talent through multiple channels',
      'Define job requirements and qualifications',
      'Develop job postings and recruitment ads',
      'Post job openings on various platforms',
      'Network with potential candidates'
    ], 
    icon: FaPeopleArrows,
  },
  {
    name: 'Applicant Screening',
    bullets: [
      'Conduct initial screening, interview and reference checks',
      'Evaluate cultural fit and personality',
      'Applicant short listing through ATS (Applicant tracking system)',
      'Present the letter of offer and contract to the candidate'
    ], 
    icon: FaHandshake,
  },
  {
    name: 'Payroll',
    bullets: [
      'Paycheck processing and distribution',
      'We will issue payment to the contracted worker in the currency of their choosing'
    ], 
    icon: FaMoneyCheckAlt,
  },
  
]

interface ServicesProps {  
}

const Services:  React.FC<ServicesProps> = () => {

  return (
    <>
      <div id="services" className="bg-white text-shadow py-6">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto lg:mx-0">
            <div className="flex flex-col items-center">
              <h2 className="text-3xl text-shadow tracking-tight  sm:text-4xl">
              Services
              </h2>
            </div>            
            <div className="flex flex-col items-center">
              <div className='lg:max-w-[75%]'>
                <p className="mt-6 text-lg leading-8">
                Looking for a hassle-free way to find the ideal candidate for your team? Say goodbye to sifting through piles of resumes and leave it to our team of expert recruiters! With our comprehensive recruitment services, we tailor our approach to your unique needs, ensuring that you find the perfect fit without the headache. We go above and beyond by conducting thorough interviews and assessments to determine if a candidate is the right match for your job requirements and company culture. We're so confident in our abilities that we'll only present you with a select few candidates, as we believe that sending more would be a disservice to you. With our help, you'll find your dream candidate in no time.
                </p>
                <p className="mt-6 text-lg leading-8">
                Looking for top-notch talent to join your team? Look no further! Our recruitment methods guarantee quality, providing you with the best candidates available in the industry. We take pride in our meticulous screening process, ensuring that we only present you with the most qualified and suitable candidates for your needs. So sit back, relax, and let us handle the heavy lifting. With our expertise and commitment to excellence, we'll find the perfect match for your team in no time.
                </p>
                <p className="mt-6 text-lg leading-8">
                Sit back, relax, and let us handle the heavy lifting.
                </p>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {services.map((so: any) => (
                <div key={so.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-shadow">
                    <div className="mb-6 flex h-16 w-16 items-center justify-center rounded-lg bg-midtone">
                      <so.icon className="text-white text-4xl" aria-hidden="true" />
                    </div>
                    {so.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                    {so.bullets.map((b:string) => (
                      <div key={b} className="flex items-baseline my-2">
                        <FaArrowRight className='text-midtone' />
                        <div key={b} className="mx-2">{b}</div>
                      </div>
                    ))}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  )
}

export default Services;
