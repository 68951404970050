import { AuthLayout, LoadingOverlay, TextField, Toast } from "../components"
import { useEffect, useRef, useState } from "react"
import { AppConfig } from "../AppConfig"
import { ContactForm } from "../models"
import { ContactFormService } from "../services"

export default function Contact() {

  const contactFormService = new ContactFormService()

  const formRef = useRef<HTMLFormElement>(null)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [showModalBusy, setShowModalBusy] = useState(true)
  const [showSendSuccess, setShowSendSuccess] = useState(false)
  const [showSendFailure, setShowSendFailure] = useState(false)
  const [modalText, setModalText] = useState('Loading')

  const  myWindow  = window as any

  const getResponseToken = async (): Promise<string> => {
    const respToken = await myWindow.grecaptcha.execute(AppConfig.recaptchaSiteKey, { action: "submit" })
    return respToken
  }

  const handleLoaded = () => {
    myWindow.grecaptcha.ready(async () => {
      console.info('DITC: Google Recaptcha Loaded')
    })
  }
  
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${AppConfig.recaptchaSiteKey}`
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
    setShowModalBusy(false)
  }, [])

  const handleSubmit = async (e:any) => {
    e.preventDefault()

    try {
      setModalText('Sending')
      setShowModalBusy(true)

      const responseToken = await getResponseToken()

      if (!responseToken) {
        throw new Error('No response from recaptcha request')
      }

      const contactForm = new ContactForm({
        firstName: firstName,
        lastName: lastName,
        email: email,
        contactNumber: phone,
        companyName: company,
        subject: subject,
        message: message,
        recatpchaToken: responseToken
      })

      const result = await contactFormService.submit(contactForm)
      if (result.success) {
        // success toast
        formRef.current && formRef.current.reset()
        setShowSendSuccess(true)
        setTimeout(() => {
          setShowSendSuccess(false)
          myWindow.location = '/'
        }, 2000)
      } else {
        // error dialog
        setShowSendFailure(true)        
        setTimeout(() => {
          setShowSendFailure(false) 
        }, 4000)
      }
    } catch (err) {
      console.error(err)
      alert('An unknown error ocurred.')
    } finally {      
      setShowModalBusy(false)
      myWindow.grecaptcha?.reset()
    }
  }
  
  return (
    <>
      <AuthLayout>
        <div className="font-montserrat">
          <div className="flex flex-col">
            <div className="mt-20">
              <h2 className="text-lg font-semibold text-gray-900">
                Drop us a line. We&apos;ll be in touch!
              </h2>            
            </div>
          </div>
          <LoadingOverlay show={showModalBusy} text={modalText} />
          <Toast show={showSendSuccess} headerText={'Successfully sent!'} bodyText={'The message was successfully sent. Someone will be in touch soon.'} isError={false}/>
          <Toast show={showSendFailure} headerText={'Error!'} bodyText={'An error occurred while sending the email. Please try again, reload the page or contact support if the problem persists.'} isError={true}/>
          <form ref={formRef}
            onSubmit={handleSubmit}
            className="mt-10 grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-2"
          >
            <TextField
              label="First name *"
              id="first_name"
              name="first_name"
              type="text"
              autoComplete="given-name"
              required
              onChange={(e) => setFirstName(e.target?.value?.trim())}
            />
            <TextField
              label="Last name *"
              id="last_name"
              name="last_name"
              type="text"
              autoComplete="family-name"
              required
              onChange={(e) => setLastName(e.target?.value?.trim())}
            />
            <TextField
              className="col-span-full"
              label="Email address *"
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              onChange={(e) => setEmail(e.target?.value?.trim())}
            />
            <TextField
              className="col-span-full"
              label="Company"
              id="company"
              name="company"
              type="company"
              autoComplete="company"
              onChange={(e) => setCompany(e.target?.value?.trim())}
            />
            <TextField
              className="col-span-full"
              label="Phone"
              id="phone"
              name="phone"
              type="phone"
              autoComplete="phone"
              onChange={(e) => setPhone(e.target?.value?.trim())}
            />
            {/* <SelectField
              className="col-span-full"
              label="How did you hear about us?"
              id="referral_source"
              name="referral_source"
            >
              <option value='-1' className='text-gray-400'>-- Select an option --</option>
              <option value='0'>Search Engine</option>
              <option value='1'>Social Media</option>
              <option value='2'>Word of Mouth</option>
              <option value='3'>Advertising</option>
            </SelectField> */}
            <TextField
              className="col-span-full"
              label="Subject"
              id="subject"
              name="subject"
              type="subject"
              autoComplete="subject"
              onChange={(e) => setSubject(e.target?.value?.trim())}
            />
            <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
              Message *
            </label>
            <textarea
              rows={7}
              name="message"
              id="message"
              className="col-span-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
              defaultValue={''}
              required
              placeholder='Enter your message here. Someone will be in touch with you shortly.'
              onChange={(e) => setMessage(e.target?.value?.trim())}
            />
            <div className="col-span-full">
              <button
                type="submit"
                className="w-full bg-accent text-white rounded h-16 hover:bg-midtone"
              >
                <span>
                  Contact Us <span aria-hidden="true">&rarr;</span>
                </span>
              </button>
            </div>
            <div
              className="g-recaptcha"
              data-sitekey={AppConfig.recaptchaSiteKey}
              data-size="invisible"
            ></div>
        </form>
        </div>
      </AuthLayout> 
    </>
  )
}