import React, { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from './Logo'
import { Link } from 'react-router-dom'

const navigation = [
  { name: 'Services', href: '/#services' },
  { name: 'Who We Are', href: '/#who-we-are' },
  { name: 'FAQ', href: '/#faq' }
]

interface NavProps {
  // define your component's props here
  className?: string,
  includeContact: boolean
}

const Nav: React.FC<NavProps> = ({className, includeContact}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <>
      <div className="relative px-6 pt-6 lg:pl-8 lg:pr-0">
        <nav className="flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
          <a href="/" aria-label="Home" className="min-w-[115px]">
            <Logo />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-shadow lg:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="hidden lg:ml-12 lg:block lg:space-x-14">
            {navigation.map((item) => (
              <Link key={item.name} to={item.href}
                className="text-sm font-semibold leading-6 text-shadow hover:text-accent hover:underline"
              >{item.name}</Link>
            ))}
            {
              includeContact && (
                <Link to={'/contact'}
                    className="text-sm font-semibold leading-6 text-white rounded bg-accent hover:text-shadow hover:bg-midtone p-2"
                >Contact</Link>
              )
            }
            
          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
            <div className="flex flex-row-reverse items-center justify-between">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              
              <a href="#" className="-m-1.5 p-1.5 h-10 min-w-[115px]"
                  onClick={() => setMobileMenuOpen(false)}
              >
                <Logo />
              </a>
            </div>
            <div className="mt-6 space-y-2">
              {navigation.map((item) => (
                <Link key={item.name} to={item.href} onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-shadow hover:text-accent"
                >{item.name}</Link>
              ))}
              
              {
                includeContact && (
                  <Link to={'/contact'} onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 bg-accent text-white hover:text-shadow hover:bg-midtone"
                  >Contact</Link>
                )
              }
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </>
  );
};

export default Nav;
