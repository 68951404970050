import clsx from 'clsx'
import { ChangeEventHandler } from 'react'

const formClasses =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'

interface LabelProps {
  id: string,  
  children: React.ReactNode
}

function Label({ id, children }: LabelProps) {
  return (
    <label
      htmlFor={id}
      className="mb-3 block text-sm font-medium text-gray-700"
    >
      {children}
    </label>
  )
}

interface TextFieldProps {
  id: string,  
  label: string,  
  type: string,   
  name: string,  
  autoComplete?: string,
  required?: boolean,
  className?: string,
  onChange?: ChangeEventHandler<HTMLInputElement>
}

export function TextField({
  id,
  label,
  type = 'text',
  className = '',
  onChange,
  ...props
}: TextFieldProps) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input id={id} type={type} {...props} className={formClasses} onChange={onChange} />
    </div>
  )
}

interface SelectFieldProps {
  id: string,  
  label: string, 
  className?: string,  
  name: string,  
  children: React.ReactNode
}

export function SelectField({ id, label, className = '', children, ...props }: SelectFieldProps) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select id={id} {...props} className={clsx(formClasses, 'pr-8')} >
        {children}
      </select>
    </div>
  )
}
