import { Container } from '.'

const faqs = [
  [
    {
      question: 'What does your recruiting firm specialize in?',
      answer:
        "At our recruiting firm, we excel at connecting top talent with companies across a range of industries. Whether you're in import and distribution, IT, healthcare, finance, or marketing, we have the expertise to find the best fit for your organization. Our team of experienced HR professionals is dedicated to understanding your specific needs and finding candidates with the skills and experience necessary to thrive in your industry. With our industry knowledge and expansive network, we'll help you find the perfect candidate for your team.",
    },
    {
      question: 'How do you find qualified candidates for job openings?',
      answer: 'We use a variety of methods to find qualified candidates for job openings, including online job boards, social media, professional networks, and our extensive database of candidates. We also have a team of recruiters who actively search for candidates who meet our clients\' specific requirements.',
    }
  ],
  [
    {
      question: 'What is the process for working with your recruiting firm?',
      answer:
        'Our process typically involves an initial consultation to understand our clients\' needs and requirements, followed by a thorough search for qualified candidates. We then present a shortlist of top candidates to our clients and arrange interviews. We provide support throughout the hiring process, including negotiating offers and onboarding assistance.',
    },
    {
      question: 'How long does it typically take to find and hire a candidate through your firm?',
      answer:
        'The timeline for finding and hiring a candidate through our firm can vary depending on the specific requirements of the job and the availability of qualified candidates. However, we strive to work efficiently and effectively to ensure our clients have access to the best possible candidates in a timely manner.',
    },
  ],
  [    
    {
      question:
        'What industries do you work with?',
      answer:
        "No matter what industry you're in, we're here to help you find the best talent for your organization. At our recruiting firm, we work with a diverse range of industries, including import and distribution, IT, healthcare, finance, and marketing, among others. Our experience in recruiting for various industries means that we have the expertise to find the right candidates for any position, from entry-level to executive level. So whether you're looking to fill a junior role or a C-suite position, you can trust us to deliver top-notch candidates that meet your specific needs.",
    },
    {
      question:
        'How do you ensure the confidentiality of our job openings and candidate information?',
      answer:
        "At our recruiting firm, we understand the importance of confidentiality in the hiring process. That's why we take it very seriously and have implemented strict policies to safeguard our clients' job openings and the information. We ensure that information is only shared on a need-to-know basis with our recruiters and potential candidates, so you can trust that your sensitive information is in good hands. We're committed to maintaining the highest levels of confidentiality throughout the recruitment process to protect your company's interests and reputation." 
      },
  ]
]

const Faqs:  React.FC = ({...props }) => {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-white text-shadow py-6 sm:py-12"
    >
      <Container className="relative">
        <div className="mx-auto  flex flex-col items-center">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight">
            Here's a list of some of the more common questions we receive.
          </p>
        </div>
        <ul
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex} className=" lg:min-h-[350px]">
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm ">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}

export default Faqs;
