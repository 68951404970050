import { Nav } from '.'
import clsx from 'clsx'
import officePeopleImage from '../images/office-people-cropped3-quarter-size.jpg'
import { FaArrowRight } from 'react-icons/fa'

interface BrandHeaderProps {
  className?: string
}

const BrandHeader:  React.FC<BrandHeaderProps> = ({className}) => {

  return (
    <>
      <div className={clsx('relative bg-white', className)}>
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>
            <Nav includeContact={true} />
            <div className="relative py-32 px-6 sm:py-12 lg:pt-56 lg:pb-12 lg:px-8 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h1 className="text-4xl font-light tracking-tight text-shadow sm:text-6xl">
                  Direct Global Recruiting
                </h1>
                <h1 className="text-2xl mt-5 tracking-tight text-shadow sm:text-3xl">
                  Recruiting made simple.
                </h1>
                <p className="mt-6 text-lg leading-8 text-shadow">
                  Looking for top talent? Look no further than Direct Global Recruiting! Our expert team specializes in finding and securing the best candidates for your company, ensuring a smooth and successful hiring process every time. We specialize in placing remote back-office workers. Our contract workers are from around the world. We can offer significant savings when it comes to fill office roles with our remote workers. If you want to hire for any of these departments, you should reach out to us first.
                </p>
                <div className="mx-auto mt-4 max-w-2xl sm:mt-6 lg:mt-8 lg:max-w-none">
                  <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-1">
                    <div className="flex flex-col">
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                        <div className="flex items-baseline my-2">
                          <FaArrowRight className='text-midtone' />
                          <div className="mx-2">Customer Service (B2B & DTC)</div>
                        </div>
                      </dd>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                        <div className="flex items-baseline my-2">
                          <FaArrowRight className='text-midtone' />
                          <div className="mx-2">Purchasing</div>
                        </div>
                      </dd>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                        <div className="flex items-baseline my-2">
                          <FaArrowRight className='text-midtone' />
                          <div className="mx-2">Procurement</div>
                        </div>
                      </dd>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                        <div className="flex items-baseline my-2">
                          <FaArrowRight className='text-midtone' />
                          <div className="mx-2">Logistics (Ocean, Air & Truck)</div>
                        </div>
                      </dd>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                        <div className="flex items-baseline my-2">
                          <FaArrowRight className='text-midtone' />
                          <div className="mx-2">Payables</div>
                        </div>
                      </dd>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                        <div className="flex items-baseline my-2">
                          <FaArrowRight className='text-midtone' />
                          <div className="mx-2">General Administration</div>
                        </div>
                      </dd>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7">
                        <div className="flex items-baseline my-2">
                          <FaArrowRight className='text-midtone' />
                          <div className="mx-2">Ecommerce management</div>
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>                
                <p className="mt-6 text-lg leading-8 text-shadow">
                And many more, send us a message or gives us a call, lets start the conversation!
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/contact"
                    className="rounded-md bg-accent text-center  w-48 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-midtone focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Let&apos;s Talk!
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src={officePeopleImage}
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default BrandHeader;
